<template>
  <v-container class="pa-0">
    <portal to="app-bar">
      <v-app-bar app dark extended extension-height="56" color="primary" elevation="0">
        <v-btn icon dark @click="onBack">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Selecione os programas</v-toolbar-title>
        <v-spacer/>
        <template v-slot:extension>
          <v-row dense>
            <v-col cols="6">
              <v-select solo flat light dense hide-details :items="places" v-model="place"/>
            </v-col>
            <v-col cols="6">
              <v-select solo flat light dense hide-details :items="fases" v-model="fase"/>
            </v-col>
          </v-row>
        </template>
      </v-app-bar>
    </portal>

    <portal to="app-footer">
      <v-footer app inset class="tertiary px-2">
        <v-btn text @click="onBack">Cancelar</v-btn>
        <v-spacer/>
        <v-btn depressed color="primary" @click="onStartAttendance">Iniciar</v-btn>
      </v-footer>
    </portal>
    
    <v-list-item-group multiple class="pa-0" v-model="selecteds">
      <template v-for="program in items">
        <v-list-item :value="program" :key="program.id" v-show="showItem(program)" v-slot:default="{active}">
          <v-list-item-action class="mr-4">
            <v-checkbox :input-value="active"/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase font-weight-bold">
              {{ program.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ mountSubtitle(program) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-container>
</template>

<script>
  import AttendanceService from '../services/attendance'

  const places = () => ([
    {value: '0', text: 'Todos'},
    {value: '2', text: 'Casa'},
    {value: '1', text: 'Escola'},
    {value: '3', text: 'Externo'},
    {value: '4', text: 'Clínica'},
  ]);

  const fases = () => ([
    {value: '0', text: 'Todas'},
    {value: '1', text: 'Manutenção'},
    {value: '2', text: 'Treino'},
    {value: '3', text: 'Linha de Base'},
  ]);

  export default {
    props: ['student', 'programs', 'id'],
    data: () => ({
      place: '0',
      fase: '0',
      items: [],
      selecteds: [],
      places: places(),
      fases: fases(),
    }),
    mounted() {
      if (this.programs) {
        this.items = this.programs.filter(program => !program.finished).map(program => ({
          visible: false, ...program,
        }));
      } else {
        this.onBack();
      }
    },
    methods: {
      onBack() {
        this.$router.push({
          name: 'student',
          query: { id: this.id },
        });
      },
      onStartAttendance() {
        if (this.selecteds.length === 0) {
          return alert('Selecione um ou mais programas para iniciar um novo atendimento.');
        }
        
        AttendanceService.store({
          student: this.student,
          programs: this.selecteds,
        })
          .then(attendance => {
            this.$router.push({
              name: 'attendance', 
              query: { id: attendance.student.id }
            });
          });
      },
      mountSubtitle(program) {
        const subtitles = [program.faseName];

        if (program.helpName) {
          subtitles.push(program.helpName);
        } else {
          subtitles.push('Sem ajuda');
        }

        if (program.helpName && program.helpName !== 'Independente') {
          subtitles.push(`${program.delay}seg`);
        }

        return subtitles.join(', ');
      },
      showItem(program) {
        return (this.place == '0' || program.places == this.place) && (this.fase == '0' || program.fase == this.fase);
      }
    },
  }
</script>
