import http from './http'

import { existsAttendance, storeAttendance, rescueAttendance, rescueAllAttendances, updateAttendance, discartAttendance } from './storage'

const create = (data) => {
  return http.post('attendances/create', data)
}

const view = (id) => {
  return http.get(`attendances/view/${id}`)
}

const trash = (data) => {
  return http.post('attendances/trash', data)
}

const store = (data) => {
  return storeAttendance(data)
}

const rescue = (data) => {
  return rescueAttendance(data)
}

const pauseds = (student) => {
  return rescueAllAttendances(student)
}

const paused = (id) => {
  return rescueAttendance(id)
}

const started = (student) => {
  return existsAttendance(student.id)
}

const storeUpdate = (attendance) => {
  return updateAttendance(attendance)
}

const storeDiscart = (attendance) => {
  return discartAttendance(attendance)
}

storeDiscart

export default { 
  create,
  view,
  store,
  trash,
  rescue,
  pauseds,
  paused,
  started,
  storeUpdate,
  storeDiscart,
}